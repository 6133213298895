import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';
import TwitterIcon from '@mui/icons-material/Twitter';

const ThankYouPage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: 'linear-gradient(to right, #FFC107 50%, #4CAF50 50%)',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#f5f5f5',
          borderRadius: '8px',
          textAlign: 'center',
          padding: '2rem',
          maxWidth: '500px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        <CheckCircleIcon sx={{ color: '#4CAF50', fontSize: '4rem', mb: 2 }} />
        <Typography variant="h4" fontWeight="bold" gutterBottom>
          Thank you!
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          We've sent your free report to your inbox so it's easy to access. You
          can find more information on our website and social pages.
        </Typography>

        <Grid container spacing={3} justifyContent="center" sx={{ mt: 2 }}>
          {/* Social Links Section */}
          <Grid item>
            <Box>
              <Typography variant="h6" gutterBottom>
                Connect With Us
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 1,
                  mt: 1,
                }}
              >
                <FacebookIcon sx={{ color: '#3b5998', cursor: 'pointer' }} />
                <LinkedInIcon sx={{ color: '#0077b5', cursor: 'pointer' }} />
                <PinterestIcon sx={{ color: '#e60023', cursor: 'pointer' }} />
                <TwitterIcon sx={{ color: '#1DA1F2', cursor: 'pointer' }} />
              </Box>
            </Box>
          </Grid>

          {/* Visit Website Section */}
          <Grid item>
            <Box>
              <Typography variant="h6" gutterBottom>
                Visit Our Website
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#4CAF50',
                  color: '#fff',
                  mt: 1,
                  '&:hover': {
                    backgroundColor: '#388E3C',
                  },
                }}
                href="https://seqnetworks.com/"
              >
                Visit Website
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ThankYouPage;
