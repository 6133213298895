import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  LinearProgress,
} from "@mui/material";
// import emailjs from "emailjs-com";
// import axios from "axios";

function App() {
  const questions = [
    { label: "Full Name", name: "fullName" },
    { label: "Company Name", name: "companyName" },
    { label: "Email Address", name: "emailAddress" },
    { label: "Phone Number", name: "phoneNumber" },
    { label: "Primary IT Concern", name: "itNeeds" },
    { label: "Number Of Employees", name: "employees" },
    { label: "Current IT Systems", name: "currentITSystems" },
    { label: "Preferred Assessment Date", name: "preferredDate", type: "date" },
    { label: "Preferred Time", name: "preferredTime", type: "time" },
  ];

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    emailAddress: "",
    phoneNumber: "",
    itNeeds: "",
    employees: "",
    currentITSystems: "",
    preferredDate: "",
    preferredTime: "",
  });

  const handleNext = () =>
    setStep((prevStep) => Math.min(prevStep + 1, questions.length - 1));
  const handlePrevious = () => setStep((prevStep) => Math.max(prevStep - 1, 0));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`https://cms.seqnetworks.com/api/event-assessments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
        }),
      });
      console.log("RESPONSE", response);
      if (response.ok) {
        // Email sent successfully
        alert("Email sent successfully!");
        setFormData({
          fullName: "",
          companyName: "",
          emailAddress: "",
          phoneNumber: "",
          itNeeds: "",
          employees: "",
          currentITSystems: "",
          preferredDate: "",
          preferredTime: "",
        });
      } else {
        // Error sending email
        alert("Error occured:", response && response.status);
      }
    } catch (error) {
      console.error("Error in creating:", error);
      alert("An unexpected error occurred. Please try again later.");
    }
  };

  // const handleSubmit = async () => {
  //   const emailParams = {
  //     ...formData,
  //   };

  //   emailjs
  //     .send(
  //       "service_9ekj01k", // Replace with your EmailJS service ID
  //       "template_bonv3mr", // Replace with your EmailJS template ID
  //       emailParams,
  //       "ibE2uQiFJb932D7Jy" // Replace with your EmailJS user ID
  //     )
  //     .then(
  //       (response) => {
  //         console.log("SUCCESS!", response.status, response.text);
  //         alert("Form submitted and email sent!");
  //       },
  //       (error) => {
  //         console.log("FAILED...", error);
  //         alert("Failed to send email.");
  //       }
  //     );
  // };

  return (
    <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "2rem" }}>
      <Typography variant="subtitle1" color="textSecondary">
        Question {step + 1} of {questions.length}
      </Typography>

      <Typography variant="h5" style={{ margin: "1rem 0" }}>
        {questions[step].label}
      </Typography>

      <TextField
        fullWidth
        variant="outlined"
        label={questions[step].label}
        name={questions[step].name}
        value={formData[questions[step].name]}
        onChange={handleChange}
        type={questions[step].type || "text"}
        style={{ marginBottom: "2rem" }}
      />

      <Box display="flex" justifyContent="space-between" width="100%">
        <Button
          variant="contained"
          color="primary"
          onClick={handlePrevious}
          disabled={step === 0}
        >
          Previous
        </Button>

        {step === questions.length - 1 ? (
          <Button variant="contained" color="secondary" onClick={handleSubmit}>
            Submit
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={handleNext}>
            Next
          </Button>
        )}
      </Box>

      <Box marginTop={4} width="100%">
        <LinearProgress
          variant="determinate"
          value={(step / (questions.length - 1)) * 100}
        />
        <Typography variant="caption" display="block">
          {Math.round((step / (questions.length - 1)) * 100)}% Complete
        </Typography>
      </Box>
    </Container>
  );
}

export default App;
